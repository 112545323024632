@import-normalize; /* bring in normalize.css styles */
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}
:root {
  --primary--10: #ebf2f8;
  --primary--20: #d0e5f5;
  --primary--30: #a8d0f0;
  --primary--40: #85c4e4;
  --primary--50: #78b2de;
  --primary--60: #599fd5;
  --primary--70: #3b81b8;
  --primary--80: #2d6fa3;
  --primary--90: #185280;
  --primary--100: #062741;
  --secondary--10: #e8f7c1;
  --secondary--20: #f5f89a;
  --secondary--30: #f7ee5f;
  --secondary--40: #f5db4a;
  --secondary--50: #f7b71e;
  --secondary--60: #e99819;
  --secondary--70: #db820f;
  --secondary--80: #cd640f;
  --secondary--90: #a54d12;
  --secondary--100: #904411;
  --support--orange--10: #f3e3b9;
  --support--orange--20: #f0d19a;
  --support--orange--30: #f6b36a;
  --support--orange--40: #f59a60;
  --support--orange--50: #f08846;
  --support--orange--60: #e36c37;
  --support--orange--70: #da5e2d;
  --support--orange--80: #cc4e22;
  --support--orange--90: #ba461d;
  --support--orange--100: #a93217;
  --support--pink--10: #f5cccc;
  --support--pink--20: #f4a9b2;
  --support--pink--30: #f46990;
  --support--pink--40: #ef527f;
  --support--pink--50: #ef467c;
  --support--pink--60: #dd327f;
  --support--pink--70: #cc2587;
  --support--pink--80: #ab1a6f;
  --support--pink--90: #8b1175;
  --support--pink--100: #720d60;
  --support--green--10: #9caba9;
  --support--green--20: #637981;
  --support--green--30: #5c8088;
  --support--green--40: #446d67;
  --support--green--50: #39605a;
  --support--green--60: #2d554a;
  --support--green--70: #2f5c51;
  --support--green--80: #1f4435;
  --support--green--90: #15361d;
  --support--green--100: #0e2812;
  --support--blue--10: #d1d1f2;
  --support--blue--20: #b8bbf0;
  --support--blue--30: #7d98e7;
  --support--blue--40: #4185e2;
  --support--blue--50: #1072c6;
  --support--blue--60: #0057b8;
  --support--blue--70: #13587f;
  --support--blue--80: #20546a;
  --support--blue--90: #194250;
  --support--blue--100: #13293a;
  --support--purple--10: #b2adb6;
  --support--purple--20: #908c92;
  --support--purple--30: #82778a;
  --support--purple--40: #6e657a;
  --support--purple--50: #625771;
  --support--purple--60: #584b6b;
  --support--purple--70: #433b63;
  --support--purple--80: #2c2c53;
  --support--purple--90: #212343;
  --support--purple--100: #141631;
  --firstcolor: #4b5161;
  --secondcolor: #fff;
  --thirdcolor: #808dad;
  --fourthcolor: #bdbdbd;
  --fifthcolor: #edeeef;
  --sixthcolor: #73759b;
  --sevencolor: #174452;
  --octavecolor: #5a6174;
  --nonecolor: #141631;
  --deccolor: #ef467c;
  --eleventhcolor: #1f4435;
  --twelfthcolor: #78b4e3;
  --thirteenth: #ffcb22;
  --fourteenthcolor: #f08846;
  --accent: #002f6c;
  --background: #fbfbfb;
  --gauge-color: #0a7041;
  --gauge-color-fill: #e0e8fd;
  --white-text: #fbfbfb;
  --dark-text: #141631;
}

body {
  //background-color: var(--primary--10) !important;
  color: var(--dark-text) !important;
}
.btn-primary {
  --bs-btn-bg: var(--primary--80) !important;
  --bs-btn-border-color: var(--primary--80) !important;
  --bs-btn-hover-bg: var(--primary--70) !important;
  --bs-btn-hover-border-color: var(--primary--70) !important;

  --bs-btn-active-bg: var(--primary--70) !important;
  --bs-btn-active-border-color: var(--primary--70) !important;
  --bs-btn-active-disabled-bg: var(--primary--50) !important;
  --bs-btn-active-disabled-bortder-color: var(--primary--50) !important;
}
.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--support--green--80);
  --bs-btn-border-color: var(--support--green--80);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--support--green--70);
  --bs-btn-hover-border-color: var(--support--green--70);
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--support--green--70);
  --bs-btn-active-border-color: var(--support--green--70);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--support--green--50);
  --bs-btn-disabled-border-color: var(--support--green--50);
}
.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--support--purple--20);
  --bs-btn-border-color: var(--support--purple--20);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--support--purple--10);
  --bs-btn-hover-border-color: var(--support--purple--10);
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--support--purple--30);
  --bs-btn-active-border-color: var(--support--purple--30);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--support--purple--10);
  --bs-btn-disabled-border-color: var(--support--purple--10);
}
.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--support--pink--80);
  --bs-btn-border-color: var(--support--pink--80);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--support--pink--70);
  --bs-btn-hover-border-color: var(--support--pink--70);
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--support--pink--60);
  --bs-btn-active-border-color: var(--support--pink--60);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--support--pink--20);
  --bs-btn-disabled-border-color: var(--support--pink--20);
}

.accordion {
  --bs-accordion-color: !important;
  --bs-accordion-bg: var(--primary--20) !important;
  --bs-accordion-border-color: var(--primary--50);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 1rem !important;
  --bs-accordion-inner-border-radius: 1rem !important;
  --bs-accordion-btn-color: !important;
  --bs-accordion-btn-bg: !important;
  --bs-accordion-btn-focus-border-color: !important;
  --bs-accordion-btn-focus-box-shadow: !important;
  --bs-accordion-active-color: var(--primary--10) !important;
  --bs-accordion-active-bg: var(--primary--50) !important;
}
.list-group {
  --bs-list-group-color: var(--primary--100);
  --bs-list-group-bg: var(--primary--20);
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.8rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: var(--primary--80);
  --bs-list-group-active-border-color: var(--primary--80);
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

.home {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.link-button,
.link-button:hover,
.link-button:active,
.link-button:focus {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  outline: 0;
  box-shadow: none;
  border-radius: 0;
}

.img-profile {
  border-radius: 50%;
  width: 110px;
  height: 110px;
  border: 4px solid var(--firstcolor);
  padding: 0;
}

.img-profile-small {
  margin: 8px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 1px solid var(--firstcolor);
  padding: 0;
}
.img-profile-small.tutor {
  border: 3px dashed var(--fourteenthcolor);
}
.xyz {
  min-width: 360px;
  overflow: scroll;
}

.smartleHome {
  width: 40vw;
}
.characters-home {
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
}
.square-Home {
  width: 90vw;
  height: 30vw;
  max-height: 350px;
  margin: 0 auto;
  position: relative;
  bottom: 30vw;
  vertical-align: middle;
  border: 1.4px solid var(--secondcolor);
}

#center-content {
  text-align: center;
}

.navigation-back {
  text-decoration: none;
  font-size: 30px;
  color: var(--dark-text);
}

.navigation-back :hover {
  color: var(--dark-text);
}

#button-wrapper {
  float: center;
}

.radioQuestion input[type="radio"] {
  position: absolute;
  left: -9999px;
}

.radioQuestion input[type="radio"] + label {
  position: relative;
  padding: 3px 0 0 40px;
  cursor: pointer;
  display: inline-flex;
  text-align: justify;
  padding-right: 3rem;
}

.radioQuestion input[type="radio"] + label:before {
  content: "";
  background: #fff;
  border: 2px solid #002f6c;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 5px;
  margin-left: 5px;
}

.radioQuestion input[type="radio"] + label:after {
  content: "";
  background: #002f6c;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 5px;
  opacity: 0;
  transform: scale(2);
  transition: transform 0.3s linear, opacity 0.3s linear;
  margin-top: 5px;
  margin-left: 5px;
}

.radioQuestion input[type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.btn {
  border-radius: 1rem;
}
.form-select,
.form-control {
  border-radius: 1rem;
}
.alert {
  border-radius: 1rem;
}
.card {
  border-radius: 1rem;
}

.student-report-header {
  text-align: center;
  margin: 0 30px;
}

.draggable {
  touch-action: none;
}
.draggable.dragging {
  opacity: 0.5;
}

.notification-container {
  width: 95%;
  height: 40vh;
  overflow: scroll;
  margin: 2rem auto;
  border: 1px solid rgba(0, 47, 108, 0.95);

  border-bottom: 10px solid rgba(0, 47, 108, 0.95);
  border-radius: 2rem;
  padding: 1rem 2rem;
  box-shadow: 0rem 0rem 0.5rem rgba(0, 0, 0, 0.25);
}

.notification {
  margin-top: 0.8rem;

  padding: 1rem 1rem;
  vertical-align: center;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 1.2rem;
  box-shadow: 0rem 0rem 0.5rem rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-columns: 0.5fr 3fr 0.8fr;
  align-items: center;
  justify-items: center;

  gap: 2rem;
  height: auto;
}
.notification .icon {
  font-size: 1.5rem;
}
.notification .title {
  font-size: 1rem;
}
.notification span {
  font-size: 0.8rem;
}
.chart-container {
  width: 70vw;
}
@media (max-width: 769px) {
  .chart-container {
    width: 80vw;
  }
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
.button {
  color: var(--dark-text);
  background-color: #ffcb22;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  height: 2.5rem;
  border: none;
}

.button:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 6px 6px rgba(0, 0, 0, 0.1);
}
.button:disabled {
  background-color: var(--secondary--50);
}
.button:disabled:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.buttonGroupSelector {
  color: var(--dark-text);
  font-size: 0.8rem;
  margin-right: 1.2rem;
  text-decoration: none;
  border-radius: 2rem;
  padding: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: var(--white-text);
}
.buttonGroupSelector::hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
}
.buttonGroupSelector.active {
  background-color: rgba(0, 47, 108);
  color: var(--white-text);
}
.time_table_wrapper {
  border-radius: 2rem;
}

.modal {
  z-index: 9999;
}
.modal-dialog,
.modal.show .modal-dialog,
.modal.fade .modal-dialog {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 80%;
  max-width: 90%;
}

@media (max-width: 769px) {
  .modal-dialog,
  .modal.show .modal-dialog,
  .modal.fade .modal-dialog {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(0%, 0%);
    margin: 0;
    width: 100%;
  }
  .modal.show .modal-content,
  .modal.fade .modal-content {
    width: 100vw;
    height: 90vh;
  }
}
.modal-body {
  max-height: 100%;
  overflow: scroll;
}
::-moz-scrollbar-button,
::-webkit-scrollbar-button {
  width: 0px;
}

.form-check-input:checked {
  border-color: var(--accent);
  background-color: var(--accent);
}

.required {
  display: inline;
  color: red;
}
blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #aaa;
  font-style: italic;
}

blockquote[dir="rtl"] {
  border-left: none;
  padding-left: 0;
  padding-right: 10px;
  border-right: 2px solid #ddd;
}

[data-slate-editor] > * + * {
  margin-top: 1em;
}
.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 768px) {
  .pro-sidebar.md.collapsed {
    left: -200px;
  }
}
.__react_component_tooltip {
  z-index: 9999 !important;
}

.trash-button {
  font-size: 1.5rem;
  color: var(--primary--100);
}

.trash-button:hover {
  font-size: 1.5rem;
  color: var(--primary--90);
}

.form-input-error {
  outline: var(--support--pink--70) 5px solid;
}

.errorBubble {
  padding: 0.2rem 1.5rem;
  background-color: var(--support--pink--70);
  width: fit-content;
  border-radius: 2rem;
  margin-top: 0.5rem;
  color: white;
}
.lead-flag-button {
  max-width: 27rem;
  text-align: right;
  transform: translateY(-3rem);
}
@media (max-width: 1300px) {
  .lead-flag-button {
    all: unset;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
}

.flag-button {
  all: unset;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.flag-button svg {
  width: 3rem;
  border-radius: 0.3rem;
}

.flag-button svg:hover {
  width: 3rem;
  filter: opacity(90%);
  border-radius: 0.3rem;
}
.paybutton {
  padding: 1rem 2rem;
}
.bubble {
  margin-left: 2rem;
  margin-right: 2rem;
  min-width: 30%;
  border-radius: 1rem;
  background-color: var(--primary--10);
  border: var(--primary--20) solid 3px;

  padding: 1rem 2rem;
  font-size: 1.2rem;
}

.homeContainer {
  margin-bottom: 3rem;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.containerProductsHome {
  height: auto !important;
}
