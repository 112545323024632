@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

$sidebar-bg-color: var(--accent);
$submenu-bg-color: rgba(0, 86, 184, 0.05);
$submenu-bg-color-collapsed: rgba(#002f6c, 0.95);
$sidebar-color: #b3b8d4;
$highlight-color: #dee2ec;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

@import "~react-pro-sidebar/dist/scss/styles.scss";

#root {
  height: 100%;
}

.app {
  height: 100vh;

  display: flex;
  position: relative;
  .logo {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    margin-top: 15px;
    margin-left: 15px;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .sidebar-btn {
      transition: width 0.3s;
      width: 150px;
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .logo {
      width: 50px;
      margin-left: 15px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .pro-item-content {
      visibility: hidden;
      display: none;
    }
    .pro-icon-wrapper {
      margin-left: 1.2rem;
    }
    .sidebar-btn {
      display: inline-block;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    header {
      margin-bottom: 40px;
      border-bottom: 1px solid #efefef;
      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;
      a {
        text-decoration: none;
        color: #888;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
    > span {
      margin-left: 10px;
    }
  }
}
.pro-sidebar > .pro-sidebar-inner {
  position: fixed;
}

.pro-item-content {
  position: relative;
}
.unreadMarker {
  background-color: red;
  color: var(--white-text);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: -17px;
  right: -17px;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .pro-sidebar.md.collapsed {
    left: -120px;
  }
}
