@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

.landing-container {
  height: fit-content;
}
/* Header */
.header {
  background-color: #002f6c;
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 900;
  min-height: 70px;

  max-height: 90px;
}

.logo-landing {
  -webkit-transition: all 1s linear;
  -moz-transition: all 1s linear;
  -o-transition: all 1s linear;
  transition: all 1s linear;
  display: block;
  width: 7rem;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.logo-landing.inactive {
  opacity: 0;
}
.logo-landing.active {
  opacity: 1;
}

.nav-toggle {
  display: none;
}

.navbar {
  position: absolute;
  text-align: center;
  top: 100%;
  left: 0;
  background-color: var(--firstcolor);
  width: 100%;
  transform: scale(1, 0);
  transform-origin: top;
  transition: transform 400ms ease-in-out;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.navbar ul li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 1em;
}

.link {
  text-decoration: none;
  color: var(--secondcolor);
  font-size: 1.1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.nav-toggle:checked + .navbar {
  transform: scale(1, 1);
}

.nav-toggle:checked + .navbar a {
  opacity: 1;
  transition: opacity 250ms ease-in-out 250ms;
}

.nav-toggle:checked + .navbar .link {
  opacity: 1;
  transition: opacity 250ms ease-in-out 250ms;
}

/* Menu Hamburguer */
.nav-toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 1em;
  height: 100%;
  display: flex;
  align-items: center;
}
.nav-toggle-label span,
.nav-toggle-label span::before,
.nav-toggle-label span::after {
  display: block;
  background: var(--secondcolor);
  height: 2px;
  width: 2em;
  border-radius: 2px;
  position: relative;
}
.nav-toggle-label span::before,
.nav-toggle-label span::after {
  content: "";
  position: absolute;
}
.nav-toggle-label span::before {
  bottom: 7px;
}
.nav-toggle-label span::after {
  top: 7px;
}
/* Menu Hamburguer 
.header button {
  position: absolute;
  margin: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  margin-right: 1em;
  width: 5rem;
}*/

.navbar {
  all: unset;
  grid-column: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
}
.header .button {
  min-width: 6rem;
}
@media screen and (min-width: 1000px) {
  .nav-toggle-label {
    display: none;
  }

  .header {
    height: 25vh;
    display: grid;
    grid-template-columns: minmax(160px, 1fr) minmax(500px, 2fr);
  }

  .logo-landing {
    grid-column: 1/2;
  }

  .navbar ul {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-right: 2rem;
  }

  .link {
    opacity: 1;
    position: relative;
  }

  .link::before {
    content: "";
    display: block;
    height: 3px;
    background: white;
    position: absolute;
    top: -0.75em;
    left: 0;
    right: 0;
    transform: scale(0, 1);
    transition: transform ease-in-out 250ms;
  }

  .link:hover::before {
    transform: scale(1, 1);
  }

  /*
  .header button {
    grid-column: 3/4;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }*/
}

.footer {
  background-image: url("../assets/images/background-landing.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 100% 100%;

  background-color: #002f6c;
  height: 13rem;
  text-align: center;
}
.footer p {
  width: 100%;
  padding-top: 8rem;
}

/* First-section */
.first-section {
  display: flex;
  background-image: url("../assets/images/background-landing.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 100% 100%;
  background-color: #002f6c;
  color: var(--white-text);
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.smartle-running {
  width: 35vw;
  margin-left: 3rem;
  margin-bottom: 3rem;
}

.logo-intro {
  max-width: 20rem;
}
.introduction-text,
.div-class {
  margin: 1rem;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: var(--white-text);
  width: 40vw;
}

.introduction-text p {
  margin: 10px 0;
  font-family: Roboto;
  font-size: 20px;
  text-align: center;
}

.brighta {
  display: none;
}
.icon-course .smartle {
  height: auto;
  width: 30vw;
  margin: 2em auto;
}
@media screen and (max-width: 768px) {
  .first-section {
    flex-direction: column-reverse;
    height: auto;
  }
  .smartle-running {
    margin: 0;
    width: 45vw;
  }
  .introduction-text {
    width: 100%;
  }
}
@media screen and (min-width: 1000px) {
  .first-section {
    width: 100%;
  }
  .brighta {
    all: unset;
    margin: 2em 2em 2em 10%;
    height: 90%;
  }
  .introduction-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
  }
  .smartle {
    all: unset;
    margin: 2em 10% 2em 2em;
    height: 90%;
  }
}

@media screen and (min-width: 1440px) {
  .introduction-text {
    font-size: 1.5rem;
  }
}

title-sections

/* Second-section */
.second-section {
  display: flex;
  flex-direction: column-reverse;
  background-color: var(--thirdcolor);
  position: relative;
  align-items: center;
  padding: 10px 0 20px 0;
}

.menu-screenshot {
  margin: 0em 2em 0em 2em;
  width: 90vw;
}

.menu-text {
  margin: 0em 3em 2em 3em;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: var(--secondcolor);
}

.title-section-hop-on {
  margin: 10px 0;
  font-family: Roboto;
  font-size: 3rem;
  font-weight: 300;
  line-height: 3.5rem;
  letter-spacing: 0em;
  text-align: center;
}

.title-sections {
  margin: 10px 0;
}
@media screen and (max-width: 768px) {
  .title-section-hop-on {
    line-height: 3rem;
  }
}
@media screen and (min-width: 768px) {
  .second-section {
    height: 60vh;
    flex-direction: inherit;
    align-items: center;
  }

  .menu-screenshot {
    margin-left: 10%;
    margin-right: 2em;
    margin-bottom: 0;
    height: 70%;
    width: auto;
  }

  .menu-text {
    margin: 0em 10% 0em 3em;
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    color: var(--secondcolor);
  }
}

@media screen and (min-width: 1440px) {
  .menu-text {
    font-size: 1.5rem;
  }
}

/*third section*/
.third-section {
  display: flex;
  flex-direction: column-reverse;
  background-color: var(--secondcolor);
  position: relative;
  align-items: center;
  padding: 10px 0 20px 0;
}

.calendar-screenshot {
  margin: 0em 2em 0em 2em;
  width: 90vw;
}

.calendar-text {
  margin: 0em 3em 2em 3em;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: var(--firstcolor);
}

@media screen and (min-width: 768px) {
  .third-section {
    height: 60vh;
    flex-direction: row-reverse;
    align-items: center;
  }

  .calendar-screenshot {
    margin-left: 2em;
    margin-right: 10%;
    margin-bottom: 0;
    height: 70%;
    width: auto;
  }
  .calendar-text {
    margin: 0em 3em 0em 10%;
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    color: var(--firstcolor);
  }
}

@media screen and (min-width: 1440px) {
  .calendar-text {
    font-size: 1.5rem;
  }
}

/* Fourth-section */
.fourth-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
}

.fourth-section div {
  height: auto;

  min-width: 30ch;
  margin: 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fourth-section div img {
  width: 80px;
  height: 80px;
  margin-top: 1em;
}

.fourth-section div p {
  color: var(--secondcolor);
  margin: 1rem;
  font-size: 1rem;
  font-style: normal;
  text-align: justify;
}

.fourth-section div h3 {
  color: var(--secondcolor);
  margin: 1rem;
  font-size: 1.5em;
  text-align: center;
}

.college {
  margin: auto;
  margin-top: 2em;
  margin-bottom: 1em;
  border-radius: 10px;
  background-color: #002f6c;
}

.essay {
  margin: auto;
  margin-top: 1em;
  margin-bottom: 1em;
  border-radius: 10px;
  background-color: #002f6c;
}

.diagnostics {
  margin: auto;
  margin-top: 1em;
  margin-bottom: 2em;
  border-radius: 10px;
  background-color: #002f6c;
}

@media screen and (min-width: 909px) {
  .fourth-section {
    max-height: 80vh;
  }

  .fourth-section div {
    max-height: 70vh;
    max-width: 60ch;
    width: 30%;
    margin: 2em 1em;
  }

  .fourth-section {
    flex-direction: inherit;
    justify-content: space-around;
  }
}

:target {
  display: block;
  position: relative;
  top: -120px;
  visibility: hidden;
}

@media screen and (min-width: 1024px) {
  .fourth-section div {
    min-width: 230px;
    width: 30%;
    max-width: 30vw;
    margin: 2em 0;
  }
}

@media screen and (min-width: 1440px) {
  .fourth-section div p {
    font-size: 1.1rem;
  }
}

.div-journey {
  background-color: #fff;

  min-height: 550px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-wrap: wrap-reverse;
}

.div-text-journey,
.div-img-journey {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;

  position: relative;
}

.div-text-journey h1 {
  color: var(--thirteenth);
  font-size: 56px;
}

.div-text-journey p {
  font-size: 30px;
  color: #bbb;
}

/* fifth-section */
.fifth-section {
  background-color: var(--twelfthcolor);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.fifth-section div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-top: 2em;
  margin-bottom: 1em;
  text-align: justify;
  color: var(--firstcolor);
  font-size: 1rem;
  gap: 3rem;
  font-family: "Roboto", sans-serif;
}

.perfil1 img {
  border: solid 5px var(--thirteenth);
  border-radius: 50%;
}

.perfil2 img {
  border: solid 5px var(--deccolor);
  border-radius: 50%;
}

.perfil3 img {
  border: solid 5px var(--thirteenth);
  border-radius: 50%;
}
.perfil4 img {
  border: solid 5px var(--deccolor);
  border-radius: 50%;
}

.fifth-section div img {
  height: 110px;
  width: 110px;
}

.fifth-section div h3 {
  margin: 0.5em;
}

.fifth-section div p {
  margin: 0.5em 10%;
}

@media screen and (min-width: 900px) {
  .fifth-section {
    flex-direction: inherit;
    justify-content: space-around;
  }
  .appraisal {
    margin-bottom: 2em;
  }
  .fifth-section div {
  }
}

/* sixth-section */
.sixth-section {
  background-color: #002f6c;
  width: 100%;
  padding-top: 15px;

  text-align: center;
  color: var(--secondcolor);
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  align-items: center;
  padding-bottom: 1rem;
}

.course-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  align-items: center;
  text-align: center;
  color: var(--secondcolor);
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;

  margin: 2rem 1rem;
}

.course-wrapper div {
  display: flex;
  flex-direction: column;

  border-radius: 10px;
  text-align: center;
  align-items: center;
  height: 100%;
  color: var(--dark-text);
  background-color: var(--white-text);
}

.course-wrapper .footer {
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 2em;
  width: 100%;
  font-size: 1.1rem;
}

.course-wrapper div p {
  font-weight: 400;
  width: 80%;
  margin: 0 auto;
  text-align: justify;
  padding: 10px;
  font-size: 15px;
  flex: 0.65;
}

.course-wrapper div ul {
  width: 80%;
  margin-left: 3rem;
  text-align: justify;
  padding: 10px;
  padding-right: 40px;
  font-size: 14px;
}

.sixth-section div img {
  height: 110px;
  width: 110px;
  margin-top: 1em;
}

.sixth-section div h3 {
  font-size: 1.75rem;
}

.about-plan {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-diagnostic {
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  width: 70%;
  margin: 0 auto;
  border: none;
  text-align: justify;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plan-price {
  display: flex;
  justify-content: center;
  align-items: baseline;
  vertical-align: bottom;
}

.sixth-section button {
  width: 50%;
}

@media screen and (min-width: 1000px) {
  .course-wrapper {
    display: grid;
    gap: 1rem;
    justify-content: space-between;
    margin: 2rem 2rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .course-wrapper > div {
    height: 100%;
    font-size: 1.5rem;
  }

  .sixth-section button {
    max-width: 300px;
  }
}
@media screen and (min-width: 1400px) {
  .course-wrapper {
    display: grid;
    gap: 6rem;
    justify-content: space-between;
    margin: 3rem 6rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* diagnostic-section */
.diagnostic-section {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  color: var(--white-text);
  background-color: var(--fourteenthcolor);
}

.div-test-diagnostic {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
}

.div-test-diagnostic h1 {
  font-weight: normal;
  font-style: normal;
  font-size: 48px;
  font-weight: 200;
  color: var(--white-text);
  margin: 30px;
}

.div-button-ld-diagnostic {
  display: flex;

  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 3rem 0;
}

.link-diagnostic {
  color: var(--dark-text);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-color: var(--thirteenth);
  width: 80%;
  height: 10vh;
  margin: 0 auto;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.link-diagnostic:hover {
  color: var(--dark-text);
}

.link-diagnostic h1 {
  font-size: 45px;
  font-weight: 399;
}
.diagnostic-section {
  flex-direction: row;
}
@media screen and (min-width: 768px) {
  .diagnostic-section {
    height: 550px;
  }

  .div-button-ld-diagnostic {
    width: 55%;
  }

  .link-diagnostic {
    width: 57%;
    height: 24.5%;
  }
}
.icon-course {
  margin-bottom: 2rem;
}

.plans {
  color: var(--firstcolor);
  background-color: var(--twelfthcolor);
  text-align: center;
  margin-top: -2rem;
  padding-top: 3rem;
}

.plans p {
  font-size: 1.2rem;
}

.plans .container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 2rem;
}

.products_container {
  text-align: left;
  margin-top: 2rem;
  display: flex;
  align-items: end;
  justify-content: center;
  padding-bottom: 3rem;
}

.card .left {
  display: flex;
}

.iconList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1rem;
}
.iconGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  margin-top: 1rem;
}
.iconGroup img {
  width: 5rem !important;
}
.iconGroup p {
  padding-top: 2rem;
  display: block;
  margin-bottom: 0;
  margin-left: 3rem;
  max-width: 20rem;
  font-weight: 500;
  font-size: 1.1rem;
}

.allPlans {
  font-size: 1.4rem;
}
@media (max-width: 1000px) {
  .iconList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    margin-bottom: 1rem;
  }
  .products_container .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  .diagnostic-section {
    flex-direction: column;
  }
  .div-test-diagnostic p {
    font-size: 1.2rem;
  }
  .div-button-ld-diagnostic {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .link-diagnostic {
    width: 20rem;
    height: 5rem;
  }
  .navbar {
    grid-column: 2/4;
    padding-right: 1rem;
  }
  .nav-toggle:checked + .navbar {
    background-color: var(--accent);
  }
  .iconGroup {
    margin-bottom: 0.5rem;
  }
}
.rights {
  background-color: var(--accent);
  color: white;
  padding: 2rem 1rem;
  text-align: center;
}
